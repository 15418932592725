<template>
  <div class="auth">
    <div class="auth-header">
      <div class="auth-header__logos">
        <a href="https://wbgrants.mininnovation.uz/" class="auth-header__logo">
          <img src="@/assets/images/logo.svg" alt="logo_uzb" />
          <span>{{ $t("SidebarLogo") }}</span>
        </a>
        <a href="https://www.worldbank.org/" class="auth-header__logo" target="_blank">
          <img src="@/assets/images/world.png" alt="logo_world" />
        </a>
        <div class="header-lang">
          <b-dropdown id="dropdown-3" :text="currentLang">
            <b-dropdown-item
              v-for="(lang, index) in langs"
              :key="index"
              @click="changeLang(lang.key)"
              >{{ lang.title }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="auth-main">
      <authLogin
        v-if="isOpenLogin"
        @toNaturalRegister="toNaturalRegister"
        @toLegalRegister="toLegalRegister"
        @forgotPassword="forgotPassword"
      />
      <authRegistration
        v-if="isOpenRegistration"
        @goBack="backToLogin"
        @getSmsToken="getSmsToken"
        :isLegal="isLegal"
        @changeUserType="getUserType"
      />
      <authConfirm
        v-if="isOpenConfirm"
        :token="sms_token"
        :phone="registerPhone"
        @resendSmsCode="resendSmsCode"
        @smsVerified="smsVerified"
      />
      <authCreateAccount
        :usertype="registrationUserType"
        :phone="registerPhone"
        :extraFields="createAccountFields"
        v-if="isOpenPassword"
      />
      <authForgot
        @goBack="backToLogin"
        v-if="forgotStep1"
        @getSmsToken="getForgotSmsToken"
      />
      <authConfirm
        v-if="isOpenForgotConfirm"
        :token="forgotSmsToken"
        :phone="forgotNumber"
        @resendSmsCode="resendForgotSmsCode"
        @smsVerified="forgotSmsVerified"
      />
      <authResetPassword
        v-if="resetPasswordStep"
        :extraFields="forgotExtraFields"
        :phone="forgotNumber"
      />
    </div>
    <div class="auth-footer">
      <span class="dev"
        >{{ $t("Developer") }}:
        <a href="https://osg.uz">Online Service Group</a></span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpenLogin: true,
      isOpenRegistration: false,
      isOpenConfirm: false,
      isOpenPassword: false,
      isLegal: false,
      sms_token: null,
      registerPhone: null,
      createAccountFields: {
        code: null,
        token: null,
      },
      registrationUserType: "",
      forgotStep1: false,
      forgotSmsToken: null,
      isOpenForgotConfirm: false,
      forgotNumber: null,
      resetPasswordStep: false,
      forgotExtraFields: {},

      langs: [
        { title: "Русский", key: "ru" },
        { title: "Engilish", key: "en" },
        { title: "O'zbekcha", key: "uz" },
      ],
      currentLang: this.$i18n.locale,
    };
  },
  created() {
    localStorage.removeItem("access")
    localStorage.removeItem("refresh")
    localStorage.removeItem("role")
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    locale && (this.currentLang = locale);
    locale && (this.$i18n.locale = locale);
    !locale && localStorage.setItem("locale", "ru");
  },
  methods: {
    changeLang(val) {
      this.$i18n.locale = val;
      this.currentLang = val;
      localStorage.setItem("locale", val);
      // this.$router.go();
    },

    backToLogin() {
      this.forgotStep1 = false;
      this.isOpenRegistration = false;
      this.isOpenLogin = true;
    },
    getForgotSmsToken({ token, phone }) {
      this.forgotSmsToken = token;
      this.forgotNumber = phone;
      this.forgotStep1 = false;
      this.isOpenForgotConfirm = true;
    },
    forgotPassword() {
      this.isOpenLogin = false;
      this.forgotStep1 = true;
    },
    toNaturalRegister() {
      this.isLegal = false;
      this.isOpenLogin = false;
      this.isOpenRegistration = true;
    },
    toLegalRegister() {
      this.isLegal = true;
      this.isOpenLogin = false;
      this.isOpenRegistration = true;
    },
    getSmsToken(arg) {
      this.sms_token = arg && arg.sms;
      this.registerPhone = arg && arg.phone;
      this.isOpenRegistration = false;
      this.isOpenConfirm = true;
    },
    smsVerified(arg) {
      this.isOpenConfirm = false;
      this.isOpenPassword = true;
      this.createAccountFields = { ...arg };
    },
    forgotSmsVerified(arg) {
      this.isOpenForgotConfirm = false;
      this.resetPasswordStep = true;
      this.forgotExtraFields = { ...arg };
    },
    resendSmsCode(token) {
      this.sms_token = token;
    },
    resendForgotSmsCode(token) {
      this.forgotSmsToken = token;
    },
    getUserType(arg) {
      this.registrationUserType = arg;
    },
  },
};
</script>

<style>
</style>
